import { MetaFunction, LoaderFunctionArgs } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { authenticator } from '@web/utilities/authenticator';
import { jsonWithFlash } from '@web/utilities/responses';

import { BenefitsSection } from './BenefitsSection/BenefitsSection';
import { CallToActionSection } from './CallToActionSection/CallToActionSection';
import { CompanySection } from './CompanySection/CompanySection';
import { FeaturesSection } from './FeaturesSection/FeaturesSection';
import { Footer } from './Footer/Footer';
import { Navigation } from './Navigation';
import { OverviewSection } from './OverviewSection/OverviewSection';

export const meta: MetaFunction = () => {
  return [
    { title: 'Onezee | Connect with brands and monetise your streams' },
    {
      name: 'description',
      content:
        "Join Onezee's platform to connect with brands, monetise your streams effortlessly, and grow your audience. Benefit from simple setup and tailored campaigns for your audience."
    },
    {
      name: 'keywords',
      content:
        'streamer, brand, collaboration, sponsor, sponsorship, affiliate, marketing'
    },
    {
      tagName: 'link',
      rel: 'canonical',
      href: 'https://onezee.io'
    }
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await authenticator.isAuthenticated(request);
  return jsonWithFlash(request, {
    isAuthenticated: !!user
  });
}

export default function Index() {
  const { isAuthenticated } = useLoaderData<{ isAuthenticated: boolean }>();

  return (
    <>
      <a
        href="https://discord.gg/3gcdpse3eY"
        target="_blank"
        rel="noreferrer"
        title="Join our Discord Community!"
      >
        <div className="fixed top-0 z-50 flex h-[52px] w-full flex-col items-center justify-center bg-violet-500 px-2 text-center font-inter text-xs font-medium leading-normal text-white xs:h-[38px] md:flex-row md:leading-8 lg:h-8">
          <span className="md:mr-[3px]">We’re live in beta!</span>
          <span className=" mr-0  md:mr-5 md:inline ">
            Check out our platform & drop your feedback in our Discord 🎉
          </span>
        </div>
      </a>
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className="scroll-smooh">
        <Navigation
          className="top-[3.25rem] xs:top-[2.4rem] lg:top-8"
          isAuthenticated={isAuthenticated}
          logoVariant="blackText"
        />
        <OverviewSection isAuthenticated={isAuthenticated} />
        <FeaturesSection />
        <BenefitsSection isAuthenticated={isAuthenticated} />
        <CompanySection isAuthenticated={isAuthenticated} />
        <CallToActionSection isAuthenticated={isAuthenticated} />
        <Footer />
      </div>
    </>
  );
}
